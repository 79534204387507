export default (context, inject) => {
    const errorPrint = (r, e) => {
        if(e.response) {
            if (e.response.data) {
                if (e.response.data.error) {
                    console.error(r, 'response-data-error:', e.response.data.error, e.response.data);
                } else {
                    console.error(r, 'response-data:', e.response.data, e.response);
                }
            } else {
                console.error(r, 'response:', e.response, e);
            }
        } else {
            console.error(r, e);
        }
    }

    inject('errorPrint', errorPrint)
    context.errorPrint = errorPrint
}