import Axios from "axios";

export const state = () => ({
    bearer: false,
    webview: false
});

export const actions = {
    updateField({ commit }, payload) {
        commit('updateField', payload);
    },
    webviewStop({commit, state}){
        if (state.webview) {
            commit('updateField', {
                field: 'webview',
                value: false
            })
            window.location.href=process.env.WEBAPPURL+(process.env.WEBAPPPORT?':'+process.env.WEBAPPPORT:'')+'/webview/fail'
        }
    },
    clearUser({commit}){
        commit('updateField', {
            field: 'bearer',
            value: false
        })
    },
    clearState({commit}) {
        commit('clearRootState')
    }
};

export const mutations = {
    updateField(state, payload) {
        state[payload.field] = payload.value;
    },
    clearRootState(rootState) {
        rootState.persistent = {
            "bearer": false
        };
    }
};