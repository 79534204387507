import Vue from 'vue'

Vue.filter('brl', function (value) {
    if (typeof value !== 'number') {
        value = parseFloat(value)
        if (isNaN(value)) {
            return '-'
        }
    }
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value)
})