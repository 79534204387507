import axios from "axios";

export const state = () => ({
    loading: false,
    has_pendingPhotosCall: false,
    refreshPhotos: false,
    loadingSaveCaption: false,
    syncingPhotos: false,
    tabActive: 'checked_in',
    params: {
        checked_in: 0,
        delivered: 0,
        limit: 30,
        published_before: null
    },
    photos: [],
    allPhotosLoaded: false,
    countPhotos: {
        checked_in: 0,
        checked_out: 0,
        delivered: 0,
        upload_not_delivered: 0
    },
    error: {
        getPhotos: null,
        disablePhoto: null,
        enablePhoto: null,
        deletePhoto: null,
        saveCaption: null,
        undoDeletedPhoto: null
    },
    layout: {
        width: 430,
        landscape: {
            description: 'Paisagem',
            canvas: {
                aspect: 1,
                widthMm: 102,
            },
            image: {
                size: {
                    width: 0.9350,
                    height: 0.7057,
                    inches: {
                        width: 3.7400,
                        height: 2.8228
                    }
                },
                aspect: 1.3249
            },
            margin: {
                top: 0.0590,
                horizontal: 0.0325,
                bottom: 0.2352,
                padding: {
                    top: 0.0590,
                    horizontal: 0.0325,
                    bottom: 0.2352
                }
            },
            byWidth: {
                height:430,
                margin:{
                    bottom:101.136,
                    horizontal:13.975,
                    top:25.369999999999997
                },
                pxPerMm:4.215686274509804,
                width:430
            }
        }, 
        square: {
            description: 'Quadrado',
            canvas: {
                aspect: 0.8,
                widthMm: 102,
            },
            image: {
                size: {
                    width: 0.9350,
                    height: 0.7559,
                    inches: {
                        width: 3.7400,
                        height: 3.7795
                    }
                },
                aspect: 0.9896
            },
            margin: {
                top: 0.0472,
                horizontal: 0.0325,
                bottom: 0.1968,
                padding: {
                    top: 0.0591,
                    horizontal: 0.0325,
                    bottom: 0.2461
                }
            },
            byWidth: {
                height:537.5,
                margin: {
                    bottom:105.78,
                    horizontal:13.975,
                    top:25.37,
                },
                pxPerMm:4.215686274509804,
                width:430,
            }
        },
        portrait: {
            description: 'Retrato',
            canvas: {
                aspect: 0.65,
                widthMm: 82.5,
            },
            image: {
                size: {
                    width: 0.9025,
                    height: 0.7598,
                    inches: {
                        width: 2.9331,
                        height: 3.7990
                    }
                },
                aspect: 0.7720
            },
            margin: {
                top: 0.0472,
                horizontal: 0.0485,
                bottom: 0.1929,
                padding: {
                    top: 0.0727,
                    horizontal: 0.0485,
                    bottom: 0.2968
                }
            },
            byWidth: {
                height:661.5384615384615,
                margin:{
                    bottom:127.61076923076922,
                    horizontal:20.855,
                    top:31.224615384615383
                },
                pxPerMm:5.212121212121212,
                width:430
            }
        }
    }
});

export const actions = {
    clearPhotos({ commit }) {
        commit('clearPhotos');
    },
    lookForCheckedIn({ commit, state, dispatch }) {
        this.$globalFuncs.firebaseProxy('event', 'tela-memorias_quero')
        if (state.params.delivered === 1 || state.params.checked_in === 0) {
            commit('clearPhotos');
            commit('lookForCheckedIn');
            dispatch('getPhotos');
        }
    },
    lookForCheckedOut({ commit, state, dispatch }) {
        this.$globalFuncs.firebaseProxy('event', 'tela-memorias_nao_quero')
        if (state.params.delivered === 1 || state.params.checked_in === 1) {
            commit('clearPhotos');
            commit('lookForCheckedOut');
            dispatch('getPhotos');
        }
    },
    lookForDelivered({ commit, state, dispatch }) {
        this.$globalFuncs.firebaseProxy('event', 'tela-memorias_entregues')
        if (!state.params.delivered) {
            commit('clearPhotos');
            commit('lookForDelivered');
            dispatch('getPhotos');
        }
    },
    getPhotos({ commit, state, dispatch }) {
        if (!state.has_pendingPhotosCall && !state.allPhotosLoaded) {
            commit('getPhotosRequest');
            commit('refreshPhotosRequest');
            this.$api.$get('/v1/me/photos',
              {
                  method: 'get',
                  params: state.params,
              })
                .then(response => {
                    if (!response.success) {
                        commit('getPhotosFailure', response.status);
                        setTimeout(() => dispatch('getPhotos',{onSuccess: () => { }}), 1800);
                    } else {
                        commit('getPhotosSuccess', response);
                        dispatch('updatePhotoCount');
                    }
                })
                .catch(error => {
                    this.$errorPrint('/photos getPhotos', error);
                    setTimeout(() => dispatch('getPhotos',{onSuccess: () => { }}), 1800);
                })
              .finally(response => {
                  commit('getPhotosFinally');
              });
        }
    },
    updatePhotoCount({ commit }) {
        axios.post('/photos', 
            {
                method: 'get',
                params: {checked_in: 1, delivered: 0, limit: 0, published_before: null}
            })
            .then(response => {
                if (response.data.error && response.data.msg === 'Usuário não autenticado.') { location.reload(true); }
                commit('sendPhotoCount', response.data);
            })
            .catch(error => {
                this.$errorPrint('/photos updatePhotoCount', error);
            });
    },
    refreshPhotos({ commit, state, dispatch }, {onSuccess}) {
        if(!state.refreshPhotos){
            commit('refreshPhotosRequest');
            commit('resetParams');
            axios.post('/photos',
                {
                    method: 'get',
                    params: { params: state.params },
                })
                .then(response => {
                    if (
                            response.data.error &&
                            response.data.msg.errors.error === 'syncing_photos'
                        ) 
                        {
                            let errorCode = {response: { status: 423 }};
                            commit('getPhotosFailure', errorCode);
                            if(state.syncingPhotos) {
                                setTimeout(() => dispatch('refreshPhotos',{onSuccess: () => { }}), 500);
                            }
                        }
                    else {
                        commit('clearPhotos');
                        commit('getPhotosSuccess', response.data);
                    }
                })
                .catch(error => {
                    this.$errorPrint('/photos refreshPhotos', error);
                    commit('clearPhotos');
                    commit('getPhotos');
                })
                .finally(response => {
                    commit('refreshPhotosFinally', onSuccess);
                });
        }
    },
    prependUploadedPhoto({commit,dispatch, rootState}, photo) {
        const photosParams = rootState.photos.params;
        const prependNow = ! photosParams.delivered && photosParams.checked_in;
        dispatch('updatePhotoCount');
        commit('prependUploadedPhoto', {prependNow, photo});
    },
    disablePhoto({ state, commit, dispatch, rootState }, { photo, onSuccess }) {
        if (state.countPhotos.checked_in === rootState.userData.plan.photos) {
            dispatch(
                'uiControl/setAlert',
                {
                    icon: 'phosficon-lock',
                    title: 'Ops...',
                    text: 'Você precisa ter pelo menos '+rootState.userData.plan.photos+' memórias para seu próximo pack. Antes de remover essa memória, por favor adicione outra.',
                    btn: 'Entendi'
                },
                {root:true}
            );
        } else {
            commit('photoCardLoading', {photoId: photo.photoKey, value: true});
            axios.post('/photos', 
            {
                method: 'put',
                urlAdd: '/v1/me/photos/'+photo.photoId,
                params: {checked_in: 0}
            })
                .then(
                    response => {
                        if (response.data.error && response.data.msg === 'Usuário não autenticado.') { location.reload(true); }
                        onSuccess();
                        commit('disablePhotoSuccess', {response});
                    }
                )
                .catch(error => {
                    this.$errorPrint('/photos disablePhoto', error);
                    commit('disablePhotoFailure', error);
                })
                .finally(response => {
                    commit('photoCardLoading', {photoId: photo.photoKey, value: false});
                    dispatch('updatePhotoCount');
                });
        }
    },
    enablePhoto({ commit, dispatch }, { photo, onSuccess }) {
        commit('photoCardLoading', {photoId: photo.photoKey, value: true});
        axios.post('/photos', 
        {
            method: 'put',
            urlAdd: '/v1/me/photos/'+photo.photoId,
            params: {checked_in: 1}
        })
            .then(
                response => {
                    if (response.data.error && response.data.msg === 'Usuário não autenticado.') { location.reload(true); }
                    onSuccess();
                    commit('enablePhotoSuccess', {response});
                }
            )
            .catch(error => {
                this.$errorPrint('/photos disablePhoto', error);
                commit('disablePhotoFailure', error);
            })
            .finally(response => {
                commit('photoCardLoading', {photoId: photo.photoKey, value: false});
                dispatch('updatePhotoCount');
            });
    },
    deletePhoto({ commit, dispatch }, { photo, onSuccess, onFailure }) {
        commit('photoCardLoading', {photoId: photo.photoKey, value: true});
        axios.post('/photos', 
        {
            method: 'delete',
            undo: false,
            urlAdd: '/v1/me/photos/'+photo.photoId,
        })
            .then(
                response => {
                    if (response.data.error && response.data.msg === 'Usuário não autenticado.') { location.reload(true); }
                    onSuccess();
                }
            )
            .catch(error => {
                this.$errorPrint('/photos deletePhoto', error);
                onFailure();
            })
            .finally(response => {
                commit('photoCardLoading', {photoId: photo.photoKey, value: false});
                dispatch('updatePhotoCount');
            });
    },
    undoDeletedPhoto({ commit, dispatch }, { photo, onSuccess, onFailure }) {
        commit('photoCardLoading', {photoId: photo.photoKey, value: true});
        axios.post('/photos', 
        {
            method: 'delete',
            undo: true,
            urlAdd: '/v1/me/photos/'+photo.photoId+'/undo-delete',
        })
            .then(
                response => {
                    if (response.data.error && response.data.msg === 'Usuário não autenticado.') { location.reload(true); }
                    onSuccess();
                }
            )
            .catch(error => {
                this.$errorPrint('/photos undoDeletedPhoto', error);
                onFailure();
            })
            .finally(response => {
                commit('photoCardLoading', {photoId: photo.photoKey, value: false});
                dispatch('updatePhotoCount');
            });

    },
    saveCaption({ state, commit }, { caption, onSuccess }) {
        if(!state.loadingSaveCaption){
            commit('photoCardLoading', {photoId: caption.photoKey, value: true});
            let simplifiedDate = "";
            if (caption.date !== null && caption.date!== "") {
                simplifiedDate = caption.date.date.split(' ')[0];
            }
            let vm = this;
            axios.post('/photos', 
            {
                method: 'post',
                urlAdd: '/v1/me/photos/'+caption.photoId+'/caption',
                params: {
                    show_date: caption.show_date ? true : false,
                    show_caption: caption.caption ? true : false,
                    show_likes: caption.likes ? true : false,
                    date: simplifiedDate,
                    caption: caption.caption,
                    likes: caption.likes
                }
            })
                .then(
                    response => {
                        if (response.data.error && response.data.msg === 'Usuário não autenticado.') { location.reload(true); }
                        commit('saveCaptionSuccess', { response, photoId: caption.photoId, onSuccess});
                    }
                )
                .catch(error => {
                    this.$errorPrint('/photos saveCaption', error);
                    // commit('saveCaptionFailure', response)
                })
                .finally(response => commit('photoCardLoading', {photoId: caption.photoKey, value: false}));
        }
    },
    regeneratelayoutbyWidth({commit}, width) {
        commit('generateLayoutbyWidth',{ width:width, aspect:'portrait'});
        commit('generateLayoutbyWidth',{ width:width, aspect:'landscape'});
        commit('generateLayoutbyWidth',{ width:width, aspect:'square'});
        commit('updateWidth', width);
    }
};

export const mutations = {
    updateWidth(state, value) {
        state.layout.width = value;
    },
    clearPhotos(state) {
        state.photos = [];
        state.params.published_before = null;
        state.allPhotosLoaded = false;
    },
    resetParams(state){
        if(state.tabActive === 'checked_in'){
            state.params.checked_in = 1;
        }else if(state.tabActive === 'checked_out'){
            state.params.delivered = 0;
        }else{
            state.params.delivered = 1;
        }
        state.params.limit = 30;
        state.params.published_before = null;
    },
    lookForCheckedIn(state) {
        state.params.checked_in = 1;
        state.params.delivered = 0;
        state.params.limit = 20;
        state.tabActive = 'checked_in';
    },
    lookForCheckedOut(state) {
        state.params.checked_in = 0;
        state.params.delivered = 0;
        state.params.limit = 20;
        state.tabActive = 'checked_out';
    },
    lookForDelivered(state) {
        state.params.delivered = 1;
        state.params.limit = 20;
        state.tabActive = 'delivered';
    },
    getPhotosRequest(state) {
        state.loading = true;
        state.has_pendingPhotosCall = true;
    },
    getPhotosSuccess(state, response) {
        state.refreshPhotos = false;
        state.syncingPhotos = false;
        let arr = [];
        let newResponseArr = [];
        if (response.data && response.data.length!==0) {
            response.data.forEach((photo, index) => {
                newResponseArr[index] = {
                    ...photo,
                    actionLoading: false
                };
            });
            state.photos = arr.concat(state.photos, newResponseArr);
            state.params.published_before = state.photos[state.photos.length-1].published_at;
            state.allPhotosLoaded = response.data.length < state.params.limit;
        }
        state.loading = false;
    },
    getPhotosFailure(state, status) {
        if(status === 423) {
            state.syncingPhotos = true;
            state.photos = [];
        } else {
            state.allPhotosLoaded = true;
        }
    },
    getPhotosFinally(state, onSuccess) {
        state.has_pendingPhotosCall = false;
    },
    refreshPhotosRequest(state) {
        state.refreshPhotos = true;
    },
    refreshPhotosFinally(state, onSuccess) {
        if (onSuccess) { onSuccess(); }
        state.refreshPhotos = false;
        state.has_pendingPhotosCall = false;
    },
    sendPhotoCount(state, response) {
        state.countPhotos = response.plan_usage.count_photos;
    },
    prependUploadedPhoto(state, {prependNow, photo}) {
        if(prependNow) {
            state.photos = concat([photo], state.photos);
        }
        state.countPhotos.checked_in++;
        state.countPhotos.upload_not_delivered++;
    },
    disablePhotoSuccess(state, { response }) {
        state.countPhotos.checked_in--;
        state.countPhotos.checked_out++;
    },
    disablePhotoFailure(state, error) {
        state.error.disablePhoto = error.data;
    },
    enablePhotoSuccess(state, { response }) {
        state.countPhotos.checked_in++;
        state.countPhotos.checked_out--;
    },
    enablePhotoFailure(state, error) {
        state.error.enablePhoto = error.data;
    },
    deletePhotoSuccess(state, { response, photo }) {
        state.countPhotos.checked_out--;
        if (photo.provider === 'device') {
            state.countPhotos.upload_not_delivered--;
        }
    },
    deletePhotoFailure(state, error) {
        state.error.deletePhoto = error.data;
    },
    undoDeletedPhotoSuccess(state, { response, photo }) {
        state.countPhotos.checked_out++;
        if (photo.provider === 'device') {
            state.countPhotos.upload_not_delivered++;
        }
    },
    undoDeletedPhotoFailure(state, error) {
        state.error.undoDeletedPhoto = error.data;
    },
    photoCardLoading(state, {photoId, value}) {
        state.photos[photoId].actionLoading = value;
    },
    saveCaptionSuccess(state, { response, photoId, onSuccess }) {
        let photoIndex;
        state.photos.forEach(function(element, index, array) {
            if (element.id === photoId) {
                photoIndex = index;
            }
            
        });
        state.photos[photoIndex].caption = response.data.data;
        onSuccess();
    },
    saveCaptionFailure(state, error) {
        state.loadingSaveCaption = false;
        state.error.saveCaption = error.data;
    },
    saveCaptionFinally(state, response) {
        state.loadingSaveCaption = false;
    },
    generateLayoutbyWidth(state, payload) {
        const height = payload.width / state.layout[payload.aspect].canvas.aspect;
        state.layout[payload.aspect].byWidth = {
            width: payload.width,
            height: height,
            margin: {
                horizontal : payload.width * state.layout[payload.aspect].margin.horizontal,
                top : height * state.layout[payload.aspect].margin.top,
                bottom : height * state.layout[payload.aspect].margin.bottom,
            },
            pxPerMm: payload.width / state.layout[payload.aspect].canvas.widthMm
        };
    }
};

export const photos = {
    namespaced: true,
    state,
    actions,
    mutations,
};