<template>
    <div class="template"></div>
</template>
<script>
export default {
    mounted() {
        this.$router.replace({
            path:'/',
            query: this.$route.query
        })
    }
}
</script>