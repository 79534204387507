// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./EffraRegular.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./EffraBold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"EffraRegular\";font-style:normal;font-weight:400;font-display:auto;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\")}@font-face{font-family:\"Effra\";font-style:normal;font-weight:400;font-display:auto;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\")}@font-face{font-family:\"Effra\";font-style:normal;font-weight:700;font-display:auto;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
