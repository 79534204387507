import createApp from './app.js'

import firestoreService from './service.firestore.js'
import functionsService from './service.functions.js'

const appConfig = {"apiKey":"AIzaSyAl9LUX-S3AIm5KYaa5y0wfEGYMlO0H-Rc","authDomain":"phosfato-webapp.firebaseapp.com","databaseURL":false,"projectId":"phosfato-webapp","storageBucket":"phosfato-webapp.appspot.com","messagingSenderId":"408556448547","appId":"1:408556448547:web:8c976f0eef39ff47a13a96","measurementId":"G-7P0WKZ6G2J"}

export default async (ctx, inject) => {
  const { firebase, session } = await createApp(appConfig, ctx)

  let servicePromises = []

  if (process.server) {
    servicePromises = [
      firestoreService(session, firebase, ctx, inject),
    functionsService(session, firebase, ctx, inject),

    ]
  }

  if (process.client) {
    servicePromises = [
      firestoreService(session, firebase, ctx, inject),
      functionsService(session, firebase, ctx, inject),

    ]
  }

  const [
    firestore,
    functions
  ] = await Promise.all(servicePromises)

  const fire = {
    firestore: firestore,
    functions: functions
  }

    inject('fireModule', firebase)
    ctx.$fireModule = firebase

  inject('fire', fire)
  ctx.$fire = fire
}

function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  if (typeof window !== "undefined" && window.$nuxt) {
  // If clause makes sure it's only run when ready() is called in a component, not in a plugin.
    window.$nuxt.$options[injectKey] = value
  }
}