const vue_fb = {};
vue_fb.install = function install(Vue, options) {
    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    window.fbAsyncInit = function onSDKInit() {
        FB.init(options);
        FB.AppEvents.logPageView()
        Vue.FB = FB;
        window.dispatchEvent(new Event('fb-sdk-ready'));
        vue_fb.sdk = FB; // do not forget this line
    };
    Vue.FB = undefined;
};

import Vue from 'vue';

Vue.use(vue_fb, {
    appId: process.env.FB_APP_ID,
    cookie: true,
    xfbml: true,
    version: 'v13.0'
});
// and this line
export default ({ app }, inject) => {
inject('fbSDK', vue_fb);
};