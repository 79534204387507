import Vue from 'vue';

export default (context, inject) => {
    const globals = {
        urls: {}
    };

    globals.urls.api = process.env.APIURL;
    globals.urls.webapp = process.env.WEBAPPURL+(process.env.WEBAPPPORT?':'+process.env.WEBAPPPORT:'');

    globals.getMonthString= function getMonthString(monthNumber)  {
        var months = ['jan','fev','mar','abr','mai','jun','jul','ago','set','out','nov','dez']
        return months[monthNumber];
    }

    globals.validateEmail = function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    globals.validateCpf = function validateCpf(cpf) {
        if (!cpf) return false;
        cpf = cpf.replace(/[^\d]+/g, '');
        // Elimina CPFs invalidos conhecidos
        if (cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999")
            return false;
        // Valida 1o digito
        let add = 0;
        for (let i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        let rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev != parseInt(cpf.charAt(9)))
            return false;
        // Valida 2o digito
        add = 0;
        for (let i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev != parseInt(cpf.charAt(10)))
            return false;
        return true;
    }

    globals.getErrorMsg = function getErrorMsg(response) {
        let hf = {
            hasMsgString: (object) => {
                if (object) {
                    if (typeof object.message === 'string') {
                        return object.message;
                    } else if (object.message && object.message[0] && typeof object.message[0] === 'string') {
                        return object.message[0];
                    } else if (object.msg && (typeof object.msg === 'string')) {
                        return object.msg;
                    } else if (object.msg && object.msg[0] && typeof object.msg[0] === 'string') {
                        return object.msg[0];
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            },
            iterateObj: (obj) => {
                for (let entry in obj) {
                    if (hf.hasMsgString(obj[entry])) {
                        return hf.hasMsgString(obj[entry])
                    } else if (typeof obj[entry] === 'object') {
                        return hf.iterateObj(obj[entry])
                    }
                }
            }
        }

        if (hf.hasMsgString(response)) {
            return hf.hasMsgString(response)
        } else if (typeof response === 'object') {
            return hf.iterateObj(response)
        } else {
            return false
        }
    }
    globals.firebaseProxy = function firebaseProxy(type, payload, eventParams) {
        if (gtag) {
            if (type === 'event') {
                gtag('event', payload, eventParams);
            } else if (type === 'id') {
                gtag('config', 'G-7P0WKZ6G2J', {
                    user_id: payload,
                    currency: 'BRL'
                });
            } else if (type === 'props') {
                gtag('set', 'user_properties', payload);
            }
        }
    }

    inject('globalFuncs', globals)
    context.$globalFuncs = globals
}