import Axios from "axios";

export const state = () => ({
    ids: [],
    plansByGroup: {},
    plansByID: {},
    plansByType: {},
    planTypeArray: {},
    packGroups: [],
    packIDs: [],
    planTypes: [],
    typeByMonths: {
        1: "MENSAL",
        3: "TRIMESTRAL",
        6: "SEMESTRAL",
        12: "ANUAL",
    },
    groupFidelityType: {
        "mini-pack": "SEMESTRAL",
        "elephant-pack": "ANUAL",
        "whale-pack": "ANUAL",
    },
    groupName: {
        "mini-pack": "MINI PACK",
        "elephant-pack": "ELEPHANT PACK",
        "whale-pack": "WHALE PACK",
    },
    plansDataReceived: false,
    selectedPlan: false,
    selectedType: false,
    filled: false
})

export const actions = {
    getPlans({rootState, state, commit, dispatch}) {
        Axios.get('/getplans')
        .then(response => {

            if (response.data.success) {
                let planSet = new Set();
                let planIDs = new Set();
                let planTypes = new Set();
                let recommendedPlan = false;

                response.data.data.forEach((receivedPlan) => {
                    planSet.add(receivedPlan.config.group);
                    planIDs.add(receivedPlan.id);

                    /*
                     * type existe no DB e está sendo redefinido abaixo. Para evitar refatoração
                     * do código todo, vou guardar o type do DB em database_type.
                     */
                    receivedPlan.database_type = receivedPlan.type

                    if (receivedPlan.config.number_of_packs) {
                        receivedPlan.type = state.typeByMonths[receivedPlan.config.number_of_packs];
                    } else {
                        receivedPlan.type = state.typeByMonths[1];
                    }

                    if (!state.plansByGroup[receivedPlan.config.group]) {
                        commit('updatePlansByGroup', {
                            field: receivedPlan.config.group,
                            value: {}
                        });
                    }

                    if (!state.plansByType[receivedPlan.type]) {
                        commit('updatePlansByType', {
                            field: receivedPlan.type,
                            value: {}
                        });
                    }

                    commit('updatePlansByGroupType', {
                        group: receivedPlan.config.group,
                        type: receivedPlan.type,
                        value: receivedPlan
                    });

                    commit('addPlansByType', {
                        type: receivedPlan.type,
                        field: receivedPlan.config.group,
                        value: receivedPlan
                    });

                    if (!recommendedPlan) {
                        recommendedPlan = receivedPlan;
                    }

                    if (receivedPlan.config.recommended) {
                        recommendedPlan = receivedPlan;
                    }

                    planTypes.add(receivedPlan.type);

                    commit('updatePlansByID', {field: receivedPlan.id, value: receivedPlan});
                });

                commit('updatePlansField', {field: 'packGroups', value: [...planSet]});
                commit('updatePlansField', {field: 'planTypes', value: [...planTypes]});
                commit('updatePlansField', {field: 'packIDs', value: [...planIDs]});

                if (!state.selectedPlan) {
                    commit('updatePlansField', {field: 'selectedPlan', value: recommendedPlan.id});
                }
                commit('updatePlansField', {field: 'selectedType', value: recommendedPlan.type});
                commit('updatePlansField', {field: 'filled', value: true});
                commit('updatePlansField', {field: 'plansDataReceived', value: true});

                dispatch('buildPlanTypeArray');
            } else {
                setTimeout(() => {
                    dispatch('getPlans');
                }, 500);
            }
        })
        .catch(error => {
            this.$errorPrint('/getplans', error);
        })
    },
    buildPlanTypeArray({ state, commit }) {
        const packTypes = ['mini-pack', 'smart-pack', 'elephant-pack', 'whale-pack'];
        let planTypesInit = {};

        state.planTypes.forEach((planType) => {
            planTypesInit[planType] = [];
        });

        commit('updatePlansField', {field: 'planTypeArray', value: planTypesInit});

        state.planTypes.forEach((planType) => {
            packTypes.forEach((packType) => {
                if (
                  state.plansByGroup[packType] &&
                  state.plansByGroup[packType][planType]
                ) {
                    commit('pushPlanTypeArray', {field: planType, value: packType});
                }
            });
        });
    },
}

export const mutations = {
    updateField(state, payload) {
        state[payload.field] = payload.value
    },
    updatePlansField(state, payload) {
        state[payload.field] = payload.value;
    },
    updatePlansByGroup(state, payload) {
        state.plansByGroup[payload.field] = payload.value;
    },
    updatePlansByType(state, payload) {
        state.plansByType[payload.field] = payload.value;
    },
    updatePlansByGroupType(state, payload) {
        state.plansByGroup[payload.group][payload.type] = payload.value;
    },
    addPlansByType(state, payload) {
        state.plansByType[payload.type][payload.field] = payload.value;
    },
    updatePlansByID(state, payload) {
        state.plansByID[payload.field] = payload.value;
    },
    pushPlanTypeArray(state, payload) {
        state.planTypeArray[payload.field].push(payload.value);
    },
}